<script setup>
import { computed } from 'vue';
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    message: 'Something went wrong',
    theme: 'default',
});

const themeClass = computed(
    () =>
        ({
            default: `bg-white ring-gray-200`,
            info: `bg-sky-100 ring-sky-300 text-sky-800`,
            warning: `bg-orange-100 ring-orange-300 text-orange-800`,
            danger: `bg-rose-100 ring-rose-300 text-rose-800`,
        })[props.theme],
);

const themeIcon = computed(
    () =>
        ({
            default: null,
            info: InformationCircleIcon,
            warning: ExclamationTriangleIcon,
            danger: ExclamationTriangleIcon,
        })[props.theme],
);
</script>

<template>
    <div
        class="flex items-center justify-between rounded p-3 font-semibold ring-1"
        :class="[themeClass]"
    >
        <div class="flex items-center space-x-2">
            <slot name="icon">
                <component v-if="themeIcon" :is="themeIcon" class="h-5 w-5" />
            </slot>
            <div>
                <slot>{{ message }}</slot>
            </div>
        </div>
        <div v-if="$slots.actions">
            <slot name="actions" />
        </div>
    </div>
</template>
